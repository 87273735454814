<template>
  <div class="hello">
    Es sind {{ anzahl }} Clients verbunden!
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      anzahl: 0
    }
  },
  created() {
    this.ws = new WebSocket(process.env.VUE_APP_WS_BACKEND + '/ws/');
    this.ws.addEventListener('message', (event) => {
      var data = JSON.parse(event.data);
      console.log('DATEN GELADEN', data)
      this.anzahl = data.anzahl
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
